import { IProduct } from 'models/product'
import { shopContext } from 'providers/shop-context'
import React, { useContext } from 'react'
import Slider from 'react-slick'

import useBreakpoints from '../../hooks/use-breakpoints'
import ProductBox from '../components/product-box'
import { SampleNextArrow, SamplePrevArrow } from './endless-slider'

export interface ProductSliderProps {
  products: IProduct[]
  title: string
  bgColor?: string
  itemcount?: number
  centeredHeadline?: boolean
  largeText?: boolean
  sizeVW?: string
  Farbe?: string
}

export const ProductSlider = ({
  products,
  title,
  itemcount,
  bgColor,
  largeText,
  centeredHeadline,
  sizeVW,
  Farbe,
}: ProductSliderProps) => {
  const breakpoints = useBreakpoints()

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow:
      products?.filter((p) =>
        p.ShopData?.find(
          (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
        )
      ).length < itemcount
        ? products?.filter((p) =>
            p.ShopData?.find(
              (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
            )
          ).length
        : itemcount,
    slidesToScroll:
      products?.filter((p) =>
        p.ShopData?.find(
          (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
        )
      ).length >= 4
        ? itemcount < 4
          ? itemcount
          : 4
        : products?.filter((p) =>
            p.ShopData?.find(
              (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
            )
          ).length < itemcount
        ? products?.filter((p) =>
            p.ShopData?.find(
              (d) => d.Shop.id === process.env.NEXT_PUBLIC_SHOPWARE_ID
            )
          ).length
        : itemcount,
    infinite: false,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },

      {
        breakpoint: 480,
        settings: {
          slidesToShow: itemcount ?? 2,
          slidesToScroll: itemcount ?? 2,
        },
      },
    ],
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
  }

  const { getIdForShopwareProduct } = useContext(shopContext)

  const filteredProducts = products?.filter(
    (x) => getIdForShopwareProduct(x) !== undefined
  )

  return (
    <>
      {filteredProducts && filteredProducts.length > 0 && (
        <div className={`w-full bg-${Farbe} max-w-full`}>
          <div className={`z-0 container mx-auto `}>
            <h2
              className={`pt-4 pb-8 ${
                largeText === true ? 'text-2xl sm:text-4xl' : 'text-xl'
              } italic font-bold font-swDisp ${
                centeredHeadline === true ? 'text-center' : ''
              }`}
            >
              {title}
            </h2>
            <div className="z-0 w-full">
              <Slider {...settings}>
                {filteredProducts.map((child, index) => (
                  <ProductBox
                    data={child}
                    showCta
                    bgColor={bgColor}
                    sizeVW={sizeVW}
                    key={child.id}
                  />
                ))}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ProductSlider.defaultProps = {
  itemcount: 4,
  bgColor: 'grey',
  centeredHeadline: false,
  largeText: false,
  sizeVW: '10',
}
export default React.memo(ProductSlider)
